export type Cell = null | 'X' | '0';
export type Column = [Cell, Cell, Cell];
export type Field = [Column, Column, Column];

const allRows = [
    [[0, 0], [0, 1], [0, 2]],
    [[1, 0], [1, 1], [1, 2]],
    [[2, 0], [2, 1], [2, 2]],

    [[0, 0], [1, 0], [2, 0]],
    [[0, 1], [1, 1], [2, 1]],
    [[0, 2], [1, 2], [2, 2]],

    [[0, 0], [1, 1], [2, 2]],
    [[0, 2], [1, 1], [2, 0]],
];

export class GameState {
    readonly field: Field
    readonly turn: number

    constructor(field: Field, turn: number) {
        this.field = field;
        this.turn = turn;
    }

    get winningRows(): [string, string, string][] {
        const rows: [string, string, string][] = [];

        for (const [[x0, y0], [x1, y1], [x2, y2]] of allRows) {
            if (this.field[x0][y0] !== null
                && this.field[x0][y0] === this.field[x1][y1]
                && this.field[x1][y1] === this.field[x2][y2]
            ) {
                rows.push([`${x0}${y0}`, `${x1}${y1}`, `${x2}${y2}`]);
            }
        }

        Object.defineProperty(this, 'winningRows', {value: rows, writable: false});

        return rows;
    }

    get isGameOver(): boolean {
        return this.turn === 9 || this.winningRows.length > 0;
    }
}

export type Coord = 0 | 1 | 2;

export class Game {
    state: GameState;

    constructor() {
        this.state = new GameState([
            [null, null, null],
            [null, null, null],
            [null, null, null],
        ], 0);
    }

    makeMove(x: Coord, y: Coord) {
        if (this.state.field[x][y] !== null) {
            throw "this field is already occupied"
        }

        if (this.state.isGameOver) {
            throw "the game is already over"
        }

        const field = [...this.state.field.map(column => [...column])] as Field;
        field[x][y] = this.state.turn % 2 === 0 ? 'X' : '0';

        this.state = new GameState(field, this.state.turn + 1);
    }
}
