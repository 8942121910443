import "./Game.scss";
import {Coord, Game, GameState} from "../game-xo";
import {useRef, useState} from "react";
import classnames from "classnames";

const useGame = () => {
    const game = useRef(new Game());
    const [state, setState] = useState<GameState>(game.current.state);

    return {
        state,
        makeMove: (x: Coord, y: Coord) => {
            game.current.makeMove(x, y);
            setState(game.current.state);
        },
        restartGame: () => {
            game.current = new Game();
            setState(game.current.state);
        },
    }
};

export default function GameXO() {
    const {state, makeMove, restartGame} = useGame();

    return <div className={classnames("game-xo", {"game-xo_over": state.isGameOver})}>
        <div className="game-xo__field">
            {state.field.map((column, x) => (
                <div key={x} className="game-xo__column">
                    {column.map((cell, y) => (
                        <button key={y} type="button"
                                className={classnames("game-xo__cell", {
                                    "game-xo__cell_win": state.winningRows.some(row => row.includes(`${x}${y}`))
                                })}
                                disabled={state.field[x][y] !== null || state.isGameOver}
                                onClick={() => makeMove(x as Coord, y as Coord)}>
                            {cell}
                        </button>
                    ))}
                </div>
            ))}
        </div>
        <div className="game-xo__controls">
            <button className="game-xo__restart" type="button" onClick={() => restartGame()}>Restart</button>
        </div>
    </div>;
}
