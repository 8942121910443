import './App.scss';
import GameXO from "./Game";
import {useEffect, useState} from "react";
import moment from "moment";

const useTime = () => {
    const [time, setTime] = useState(() => Math.round(Date.now() / 1000));

    useEffect(() => {
        const interval = setInterval(() => setTime(Math.round(Date.now() / 1000)), 100);
        return () => clearInterval(interval);
    }, [])

    return time;
}

export default function App() {
    const time = useTime();

    return <div className="app">
        <span className="app__time">{ moment.unix(time).format('HH:mm:ss') }</span>
        <div className="app__game"><GameXO/></div>
    </div>;
};
